import clsx from 'clsx'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useWindowScroll } from '@/hooks/useWindowScroll'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './SideTabs.module.scss'

interface SideTabsItemProps {
  label: string
  id: string
}
interface SideTabsProps extends DefaultComponentProps {
  items: SideTabsItemProps[]
  contentElement: HTMLDivElement | null
}
const SideTabs: FC<SideTabsProps> = ({
  className = '',
  items = [],
  contentElement = null
}) => {
  const itemsRef = useRef<HTMLAnchorElement[]>([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [isItemClicked, setItemClicked] = useState(false)
  const [contentElementHeight, setContentElementHeight] = useState(0)
  const [bgStyle, setBgStyle] = useState({ top: 0, height: 0 })
  const [windowScrollState] = useWindowScroll()
  const { y: windowScrollY } = windowScrollState

  useEffect(() => {
    if (!contentElement) {
      return
    }

    function outputsize() {
      setContentElementHeight((contentElement as HTMLDivElement).offsetHeight)
    }
    outputsize()

    new ResizeObserver(outputsize).observe(contentElement)
  }, [contentElement])

  const targets = useMemo(() => {
    if (!itemsRef.current.length || !items.length) {
      return []
    }

    return itemsRef.current.map(anchorElement => {
      const { top = 0, height = 0 } =
        document
          // @ts-ignore
          .querySelector(anchorElement.getAttribute('href'))
          ?.getBoundingClientRect() ?? {}
      const topPos = top + window.scrollY

      return {
        top: topPos,
        bottom: topPos + height
      }
    })
  }, [contentElementHeight])

  useEffect(() => {
    if (isItemClicked) {
      return
    }
    let i = 0
    targets.forEach(({ top }, index) => {
      if (windowScrollY >= top - 100) {
        i = index
      }
    })
    setActiveIndex(i)
  }, [windowScrollY, targets, contentElementHeight, isItemClicked])

  const handleClick = useCallback((index: number) => {
    let timeout: any
    setItemClicked(true)
    setActiveIndex(index)
    timeout = setTimeout(() => {
      setItemClicked(false)
    }, 700)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const recalcBgStyles = useCallback((index: number) => {
    const { offsetHeight: height = 0, offsetTop: top = 0 } =
      itemsRef.current?.[index] || {}
    setBgStyle({ top, height })
  }, [])

  const handleEnter = useCallback(
    (index: number) => {
      recalcBgStyles(index)
    },
    [recalcBgStyles]
  )

  const handleLeave = useCallback(() => {
    recalcBgStyles(activeIndex)
  }, [activeIndex, recalcBgStyles])

  useEffect(() => {
    recalcBgStyles(activeIndex)
  }, [activeIndex, recalcBgStyles])

  return (
    <nav className={clsx(styles['side-nav'], className)}>
      {items.map(({ label, id }, index) => (
        <a
          title={label}
          key={index}
          className={clsx(styles['side-nav__link'], {
            [styles['side-nav__link_active']]: index === activeIndex
          })}
          ref={el =>
            (itemsRef.current[index as number] = el as HTMLAnchorElement)
          }
          onClick={() => handleClick(index)}
          onMouseEnter={() => handleEnter(index)}
          onMouseLeave={handleLeave}
          href={`#${id}`}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      ))}
      <div className={styles['side-nav__bg']} style={bgStyle} />
    </nav>
  )
}
export default SideTabs

import { useTranslation } from 'next-i18next'
import { FC, useEffect } from 'react'

import Heading from '@/components/Heading'
import NetworkCard from '@/components/NetworkCard'
import NetworkProps from '@/components/NetworkCard/NetworkProps.interface'

import networksConfig from '../../config/networks'

import styles from './Networks.module.scss'
import { useUi } from '@/bus/ui'
import { AboutNetworks, Reward } from '@/sections'

export const Networks: FC = () => {
  const { t } = useTranslation('networks')
  const NETWORKS = t('networks', {
    returnObjects: true
  }) as NetworkProps[]

  const { setSettings } = useUi()

  useEffect(() => {
    setSettings({ menu: true, footer: true })
  }, [])

  return (
    <>
      <AboutNetworks />
      <section className={styles['networks']}>
        <div className={styles['networks__inner']}>
          <Heading level={3} className={styles['networks__heading']}>
            {t('heading')}
          </Heading>
          <div className={styles['networks__list']}>
            {Object.entries(NETWORKS).map(([key, value]) => (
              <NetworkCard
                key={key}
                className={styles['networks__list-item']}
                {...{ ...value, id: key, ...networksConfig[key] }}
              />
            ))}
          </div>
        </div>
      </section>
      <Reward />
    </>
  )
}

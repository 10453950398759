import React, { FC, ReactNode } from 'react'

import styles from '@/contents/Network/Network.module.scss'
import Tag from '@/contents/Network/subcomponents/Tag'

interface ListProps {
  type: string
  items: string[] | ReactNode[]
  id: string
}

interface ItemProps {
  item: string | any[]
  id: string
}

// @ts-ignore
const Item: FC<ItemProps> = ({ item, id }) => {
  if (Array.isArray(item)) {
    return item.map((i, idx) => {
      return (
        <React.Fragment key={idx}>
          {Object.entries(i).map(([key, value]) => (
            <Tag key={idx} type={key} id={id} items={value as string} />
          ))}
        </React.Fragment>
      )
    })
  }

  return <div dangerouslySetInnerHTML={{ __html: item }} />
}

export const List: FC<ListProps> = ({ type = 'ul', items, id }) => {
  const Component = type

  return (
    // @ts-ignore
    <Component className={styles['network__list']}>
      {items.map((item, index) => (
        <li className={styles['network__list-item']} key={index}>
          <div>
            <Item id={id} item={item as any} />
          </div>
        </li>
      ))}
    </Component>
  )
}

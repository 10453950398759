import clsx from 'clsx'
import { FC, useCallback, useEffect, useRef, useState } from 'react'

import styles from '@/contents/Network/Network.module.scss'

interface ImageProps {
  id: string
  name: string
}
export const Image: FC<ImageProps> = ({ id = '', name = '' }) => {
  const [dimensions, setDimesions] = useState({ width: 0 })
  const imageRef = useRef<HTMLImageElement>(null)
  const [loaded, setLoaded] = useState(false)
  const [isFloating, setFloating] = useState(true)
  const handleLoad = useCallback(() => {
    setLoaded(true)
  }, [])

  useEffect(() => {
    // @ts-ignore
    if (imageRef?.current?.complete) setLoaded(true)
  }, [])

  useEffect(() => {
    if (!loaded) {
      return
    }
    // @ts-ignore
    const { naturalWidth } = imageRef.current
    const halfWidth = naturalWidth / 2
    setFloating(halfWidth < 420)
    setDimesions({ width: halfWidth })
  }, [loaded])

  return (
    <div
      className={clsx(styles['network__image'], {
        [styles['network__image_floating']]: isFloating
      })}
    >
      <img
        ref={imageRef}
        {...dimensions}
        onLoad={handleLoad}
        className={styles['network__image-item']}
        src={`/images/networks/${id}/${name}`}
        alt={''}
      />
    </div>
  )
}

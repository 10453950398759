import { FC } from 'react'

import styles from '@/contents/Network/Network.module.scss'

interface ParagraphProps {
  text: string
}
export const Paragraph: FC<ParagraphProps> = ({ text = '' }) => (
  <p
    className={styles['network__paragraph']}
    dangerouslySetInnerHTML={{ __html: text }}
  />
)

import { FC } from 'react'

import HeadingComponent from '@/components/Heading'

import styles from '@/contents/Network/Network.module.scss'

interface HeadingProps {
  text: string
  id?: string
  level?: string
}

export const Heading: FC<HeadingProps> = ({
  text = '',
  id = '',
  level = 'h4'
}) => {
  return (
    <HeadingComponent
      className={styles[`network__${level}`]}
      level={+level.replace('h', '')}
    >
      <>
        {level === 'h3' && (
          <span id={id} className={styles['network__h3-anchor']} />
        )}
        <span
          dangerouslySetInnerHTML={{
            __html: text
          }}
        />
      </>
    </HeadingComponent>
  )
}

import { FC } from 'react'

import styles from '@/contents/Network/Network.module.scss'

interface ImageProps {
  url: string
  label?: string
}
interface GroupImagesProps {
  images: string[] | ImageProps[]
  id: string
}
export const GroupImages: FC<GroupImagesProps> = ({ images = [], id = '' }) => (
  <div className={styles['network__images']}>
    {images.map((item, index) => {
      if (typeof item === 'string') {
        return (
          <img
            className={styles['network__images-item']}
            key={index}
            src={`/images/networks/${id}/${item}`}
            alt={''}
          />
        )
      } else {
        return (
          <figure key={index} className={styles['network__images-figure']}>
            <img
              className={styles['network__images-figure-image']}
              key={index}
              src={`/images/networks/${id}/${item.url}`}
              alt={''}
            />
            {item.label && (
              <figcaption className={styles['network__images-figure-caption']}>
                {item.label}
              </figcaption>
            )}
          </figure>
        )
      }
    })}
  </div>
)

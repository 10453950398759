import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import Button from '@/components/Button'
import Heading from '@/components/Heading'
import Icon from '@/components/Icon'
import Link from '@/components/Link'
import NetworkProps from '@/components/NetworkCard/NetworkProps.interface'

import styles from './NetworkCard.module.scss'
import { routes } from '@/config'

const NetworkCard: FC<NetworkProps> = ({
  className = '',
  id,
  bg,
  color,
  short: {
    title = '',
    summary = '',
    volume = null,
    model = null,
    format = null
  } = {}
}) => {
  const { t } = useTranslation()

  return (
    <div className={clsx(styles['network-card'], className)}>
      <div
        className={styles['network-card__heading']}
        style={{ backgroundColor: bg }}
      >
        <Icon
          className={styles['network-card__heading-icon']}
          name={`networks/${id}/${id}`}
        />
        <div
          className={styles['network-card__heading-text']}
          style={{ color }}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>
      <div className={styles['network-card__summary']}>
        <div
          className={styles['network-card__summary-group']}
          dangerouslySetInnerHTML={{ __html: summary }}
        ></div>
        {volume && (
          <div className={styles['network-card__summary-group']}>
            <Heading
              level={4}
              className={styles['network-card__summary-heading']}
            >
              {t('networks:volume')}
            </Heading>
            <span dangerouslySetInnerHTML={{ __html: volume }} />
          </div>
        )}
        {model && (
          <div className={styles['network-card__summary-group']}>
            <Heading
              level={4}
              className={styles['network-card__summary-heading']}
            >
              {t('networks:model')}
            </Heading>
            <span dangerouslySetInnerHTML={{ __html: model }} />
          </div>
        )}
        {format && (
          <div className={styles['network-card__summary-group']}>
            <Heading
              level={4}
              className={styles['network-card__summary-heading']}
            >
              {t('networks:format')}
            </Heading>
            <span dangerouslySetInnerHTML={{ __html: format }} />
          </div>
        )}
      </div>

      <div className={styles['network-card__footer']}>
        {id === 'getuniq' ? (
          <Button
            title={t('forms:buttons.connectionRequest') as string}
            className={styles['network-card__button']}
            as={Link}
            href={{
              pathname: routes.SUPPORT,
              query: { title: t('networks:suggest_service') },
              external: true
            }}
            size={'m'}
            before={<Icon name={'arrows/right/24'} />}
          >
            {t('forms:buttons.connectionRequest')}
          </Button>
        ) : (
          <Button
            title={t('forms:buttons.more') as string}
            className={styles['network-card__button']}
            as={Link}
            href={`${routes.NETWORKS}/${id}`}
            size={'m'}
            before={<Icon name={'arrows/right/24'} />}
          >
            {t('forms:buttons.more')}
          </Button>
        )}
      </div>
    </div>
  )
}
export default NetworkCard

import { useTranslation } from 'next-i18next'
import { NextSeo } from 'next-seo'
import { FC, useEffect } from 'react'

import Accordion from '@/components/Accordion'
import Heading from '@/components/Heading'

import styles from './Texts.module.scss'
import { useTexts } from '@/bus/texts'
import { useUi } from '@/bus/ui'
import { Content } from '@/layouts'

interface TextsComponentProps {
  slug: string
}

export const Texts: FC<TextsComponentProps> = ({ slug = null }) => {
  const {
    getTextsBySlug,
    clearTexts,
    data: { header = '', text = '', items = [] } = {}
  } = useTexts()

  const { setSettings } = useUi()
  const {
    t,
    i18n: { language: lang }
  } = useTranslation()

  useEffect(() => {
    setSettings({ menu: true, footer: true })

    if (!slug) {
      return
    }

    getTextsBySlug({ slug, lang })

    return () => {
      clearTexts()
    }
  }, [slug])

  if (!slug) {
    return null
  }

  return (
    <>
      <NextSeo title={t(`${slug}:head.title`) as string} />
      <Content>
        <div className={styles['texts']}>
          <Heading className={styles['texts__heading']} level={1}>
            {header}
          </Heading>
          <div
            className={styles['texts__content']}
            dangerouslySetInnerHTML={{ __html: text }}
          />
          {items?.length > 0 && <Accordion items={items} />}
        </div>
      </Content>
    </>
  )
}

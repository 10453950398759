import { FC } from 'react'

import styles from './Table.module.scss'

interface TableProps {
  items: [][]
}
const Table: FC<TableProps> = ({ items = [] }) => (
  <table className={styles.table}>
    {items.map((row: [], index: number) => (
      <tr key={index}>
        {row.map((col, index) => (
          <td key={index}>{col}</td>
        ))}
      </tr>
    ))}
  </table>
)
export default Table

import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC, useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'

import SideTabs from '@/components/SideTabs'

import styles from './Network.module.scss'
import { useUi } from '@/bus/ui'
import HeadingProps from '@/contents/Network/subcomponents/HeadingProps.d'
import Tag from '@/contents/Network/subcomponents/Tag'
import { AboutNetwork, Reward } from '@/sections'

interface ContentProps {
  [key: string]: string | string[]
}

export const Network: FC = () => {
  const { t } = useTranslation('networks')
  const router = useRouter()
  const contentRef = useRef<HTMLDivElement>(null)

  const { id: spaID = null } = useParams()
  const id = useMemo(() => spaID || router?.query?.id, [router.query, spaID])

  const { setSettings } = useUi()

  const NAV = useMemo(() => {
    if (!id) {
      return []
    }
    return t(`networks.${id}.full.nav`, {
      returnObjects: true
    }) as HeadingProps[]
  }, [id, t /*, spaID*/])

  const CONTENT = useMemo(() => {
    if (!id) {
      return []
    }
    return t(`networks.${id}.full.content`, {
      returnObjects: true
    }) as ContentProps[]
  }, [id, t])

  useEffect(() => {
    setSettings({ backButton: true, menu: true, footer: true })
    return () => {
      setSettings({ backButton: false })
    }
  }, [])

  return (
    <>
      <AboutNetwork id={id as string} />
      <section className={styles['network']}>
        <div className={styles['network__side']}>
          <SideTabs contentElement={contentRef.current} items={NAV} />
        </div>
        <div ref={contentRef} className={styles.network__content}>
          {CONTENT.map((item, index) =>
            Object.entries(item).map(([key, value]) => (
              <Tag type={key} id={id as string} items={value} key={index} />
            ))
          )}
        </div>
      </section>
      <Reward />
    </>
  )
}
